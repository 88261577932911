*,*::after,*::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --dark-color: #344767;
  --light-color: #f0f2f5;
  /* --main-color: #4D44B5; */
  --main-color: #0a7518;
  --secondary-color: #EDECF7;
  --bg-color: #E6EBEE;
  --padding: 20px;
}

/* global styling starts */
img{
  max-width: 100%;
}

a{
  text-decoration: none;
}

ul{
  list-style: none;
}

.flex{
  display: flex;
}

.flex-direction-column{
  flex-direction: column;
}

.justify-content-center{
  justify-content: center;
}

.justify-content-between{
  justify-content:  space-between;
}

.justify-content-around{
  justify-content: space-around;
}

.align-items-center{
  align-items: center;
}

/* Page styling starts */
.page{
  min-height: 100vh;
  width: 100vw;
}

.sidebar.active{
  width: 20em;
  background-color: var(--main-color);
  padding: var(--padding);
  display: block;
  flex-shrink: 0;
}

.sidebar{
  display: none;
}

.sidebar.active + .main-content{
  width: calc(100% - 20em);
}

.main-content{
  background-color: var(--light-color);
  flex: 1;
  max-width: 100%;
}

.body-wrapper{
  padding: var(--padding);
  position: relative;
}

/* Nav styling starts */
.nav{
  background-color: #fff;
  padding: var(--padding);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Page title */
.page-title-wrapper{
  margin-top: 2em;
  margin-bottom: 3em;
}

/* profile nav item */

.user{
  font-size: 1em;
}

/* Data table style starts */
.MuiDataGrid-toolbarContainer{
  flex-direction: row-reverse;
}

.MuiDataGrid-columnHeaders{
  background-color: var(--main-color);
  color: white;
  margin-top: 10px;
}

.MuiDataGrid-columnHeaderTitleContainer .MuiSvgIcon-root {
  color: white;
}

/* .MuiDataGrid-columnHeaders{
  background-color: var(--main-color);
  color: white;
} */

.actions{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Single Student Profile */


.profile-info-group{
  margin-bottom: 20px;
}

.profile-field-title{
  font-size: 1.2em;
  font-weight: bold;
  color: var(--dark-color);
}

.info-text{
  color: var(--main-color);
  font-size: 1em;
  font-weight: bold;
}

/* Form styling */
.form-group{
  display: flex;
  margin-bottom: 20px;
}

/* Id Card */

.id-card{
  border-radius: 8px;
  height: 365px;
  width: 230px;
  background-size: 110% 110%;
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-position: bottom;
  background-position-y: -18px;
  background-position-x: -11px;

}

.id-card-content-header{
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.id-card-content-header img{
  height: 50%;
  aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: contain;
}

.bismillah {
  height: 30px;
  width: 100%;

}

.bismillah img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.id-card-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.id-card-profile{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.id-card-profile img{
  border-radius: 50%;
  width: 150px;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center center;
}

.id-card-info-title h2{
  font-size: 1.2em;
  margin: 10px 0;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.id-card-info-main ul{
  display: flex;
  flex-direction: column;
}

.id-card-info-main ul li{
  display: flex;
}

.id-card-info-main ul li span{
  flex-basis: 50px;
  color: #fff;
}

input.student_attendance_date{
  padding-right: 32px;
  height: auto;
  min-height: 1.4375em;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: content-box;
  background: none;
  display: block;
  min-width: 0;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding-top: 16.5px;
  padding-bottom: 16.5px;
  padding-left:  14px;
  text-align: left;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  border-color: rgba(0, 0, 0, 0.23);
}

.title{
  font-size: 1.4em;
  margin-bottom: 1em;
}

.MuiTextField-root{
  width: 100%;
}

.shadow{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* transform: translate(50%, 50%); */
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formField{
  margin-bottom: 1em;
}

.profile-box{
  width: 60%;
  aspect-ratio: 1/1;
  border-radius: 8px;
  background-color: var(--dark-color);
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.field-input{
  margin-bottom: 20px;
}

/* .css-i4bv87-MuiSvgIcon-root{
  color: #fff;
  width: 3em !important;
  height: 3em !important;
} */

.result-showing-box{
  gap: 10px;
}

.result-icon{
  background-color: var(--main-color);
  color: white;
  height: 80px;
  aspect-ratio: 1/1;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 2em;
}

.form-field{
  margin-bottom: 10px;
  padding-top: 10px;
}

.teacher-profile-wrapper{
  width: 100%;
  height: 300px;
  border-radius: 4px;
  background-color: #f4f4f4;
  overflow: hidden;
  object-fit: cover;
}

.teacher-profile-wrapper img{
  width: 100%;
  object-fit: cover;
  object-position: top center;
}

.float{
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    aspect-ratio: 1 / 1;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    font-size: 4rem;
    color: var(--main-color);
    border-radius: 12px;
}

textarea{
  width: 100%;
  padding: 1em;
  border-radius: 12px;
}

/* .MuiTableCell-head{
  color: #fff !important;
}

.MuiTableCell-head .MuiSvgIcon-root{
  color: #fff !important;
} */

.teacher-profile-wrapper{
  width: 300px;
  aspect-ratio: '1/1';
  background: '#f4f4f4';
}

.upload-media-wrapper{
  border: dashed 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition:cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-bottom: 20px;
}

.upload-media-wrapper:hover{
  border-color: rgba(0, 0, 0, 0.5);
}

.file-upload-icon{
  font-size: 5em;
  color: rgba(0, 0, 0, 0.5);
}


/* login page */

.login-page{
  width: 100vw;
  overflow: hidden;
  min-height: 100vh;

}

.login-page .login-wrapper{
  height: 100vh;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 2rem 3rem;
}

.login-page .login-wrapper .row{
  width: 100%;
}

.hostel-name.title{
  font-size: 3em;
  line-height: 1em;
}

.add-room-btn{
  font-size: 2em;
  color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.assigned-list{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0a751845;
  padding: 1em;
  border-radius: 4px;
}

.assigned-list .unassign-btn{
  font-size: 1.5em;
  color: red;
  cursor: pointer;
}

.payment-fields-wrapper, .box-wrapper{
  background-color: #0a751845;
  padding: 1em;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.box-wrapper .MuiYearCalendar-root{
  width: 100%;
}

.attendance-chip{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  aspect-ratio: 1/1;
  border-radius: 4px;
  margin-left: 2px;
  margin-right: 2px;
  padding: 3px;
  color: #fff;
  font-family: 'Courier New', Courier, monospace;
}

.attendance-chip.present{
  background-color: #0a7518;
}

.attendance-chip.absent{
  background-color: #d32f2f;
}

.attendance-chip.no-record{
  color: #000;
  background-color: rgba(0, 0, 0, 0.04);
}

.voucer{
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 2em;
}

.voucer_header{
  display: flex;
  justify-content: center;
  align-items: center;
}

.voucer_body{
  margin: 2em 0;
}

table{
  width: 100%;
  padding: 10px;
  border-radius: 2px;
  background-color: #0a75184a;
}

.voucer_body tr{
  text-align: left;
  padding: 10px;
}

.voucer_body tbody td, .voucer_body thead th{
  border-bottom: dashed 1px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.voucer_footer{
  padding-top: 3em;
  padding-bottom: 1em;
}

.certificate{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 700px;
  padding: 2em;
}

.certificate .header{
  text-align: center;
}

.certificate .header h1{
  font-size: 2.5em;
  color: #333;
}

.certificate .header h2{
  font-size: 1.5em;
  color: #333;
}

.certificate .result-title{
  text-align: center;
}

.certificate .result-title h3{
  text-decoration: underline;
}

.certificate .result-table thead tr th{
  text-align: left;
  padding: 10px 0;
}

.certificate .result-table tbody tr td{
  padding: 10px 0;
}

.certificate .body .student{
  display: flex;
  justify-content: left;
  flex-direction: column;
  gap: 7px;
}

.divider{
  width: 100%;
  height: 1px;
  background-color: #333;
  margin-top: 20px;
  margin-bottom: 20px;
}

.invoice-header{
  background-color: var(--main-color);
  border-radius: 12px;
  padding: 40px 20px;
  text-align: center;
  color: #fff;
  font-size: 1.2em;
  margin-top: 20px;
  margin-bottom: 20px;
}

.invoice-body table thead tr th{
  text-align: left;
  font-size: 1.3em;
}

.invoice-body table tr th, .invoice-body table tr td{
  padding: 1em 0;
  font-size: 1.2em;
}


/* Bootstrap table */
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

table {
  border-collapse: collapse;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table td, .table th {
  padding: 0.75rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}

/* Payment Profile */
.payment-profile-box{
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
}

.payment-profile-box .profile{
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-profile-box .payment-profile{
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.payment-profile-box .payment-profile-body .table tr th{
  text-align: left;
}